import React from 'react';
import { Container, RichText } from './styles';
import { Link } from 'react-router-dom';
import { AppContentContainer } from '../../styles';
import { useTrackingStore } from '../../services/TrackingService';

export default function BasicPage({richtext, includeDatenschutzLink}) {

  return (
    <AppContentContainer>
      <Container>
        {includeDatenschutzLink && (
          <div style={{marginBottom: 20}}>
            <Link to="/datenschutz" onClick={()=>{useTrackingStore.getState().trackPageView('/datenschutz', 'datenschutz')}}>Zur Datenschutzerklärung</Link>
          </div>
        )}
        <RichText dangerouslySetInnerHTML={{__html: richtext}}></RichText>
      </Container>
    </AppContentContainer>
  );
}