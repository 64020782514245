import styled from 'styled-components';
import { absoluteFill, center, colors } from '../../styles';
import { mediaMax, mediaMin } from '../../common/breakpoints';

const VectorContainer = styled.div`
  position: relative;
  display: inline-block;
  color: ${props => props.disabled ? colors.lightGray : colors.main};
  vertical-align: middle;
  margin: 0 4px;
  svg {
    position: absolute;
    left: 0;
    right: 0;
  }
  cursor: pointer;
  ${mediaMax('small',`
    display: none;
  `)}
`;

export const ArrowLeftVector = ({size, onClick, strokeWidth, disabled}) => (
  <VectorContainer style={{width: size, height: size}} onClick={onClick} disabled={disabled}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"stroke="currentColor" fill='transparent' strokeWidth={strokeWidth} viewBox="0 0 10 10">
      <path d="M 7 1 L 2 5 L 7 9"/>
    </svg>
  </VectorContainer>
);

export const ArrowRightVector = ({size, onClick, strokeWidth, disabled}) => (
  <VectorContainer style={{width: size, height: size}} onClick={onClick} disabled={disabled}>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"stroke="currentColor" fill='transparent' strokeWidth={strokeWidth} viewBox="0 0 10 10">
      <path d="M 3 1 L 8 5 L 3 9"/>
    </svg>
  </VectorContainer>
);

export const ArrowContainer = styled.div`
  ${absoluteFill}
  ${mediaMax('small',`
    display: none;
  `)}
`;

const ArrowButton = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  user-select: none;
  &:hover{
    opacity: 1;
    .arrow-wrapper{
      transform: translateY(-50%);
    }
  }
  .arrow-wrapper{
    position: absolute;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    border: 1px solid ${colors.main};
    transition: transform 0.2s ease-out;
  }
`;

export const PreviousButton = styled(ArrowButton)`
  left: 0;
  .arrow-wrapper {
    transform: translate(4px, -50%);
    left: 10px;
  }
`;

export const NextButton = styled(ArrowButton)`
  right: 0;
  .arrow-wrapper {
    transform: translate(-4px, -50%);
    right: 10px;
  }
`;

export const Button = styled.div`
  display: inline-block;
  border-radius: 20px;
  padding: 5px 12px;
  margin-right: 6px;
  border: 1px solid ${colors.main};
  color: ${colors.main};
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  filter: drop-shadow(0px 0px 0px ${colors.main});
  &:hover {
    transform: translate(-1px, -2px);
    filter: drop-shadow(1px 2px 0px ${colors.main});
  }
  user-select: none;
  ${mediaMax('small',`
    margin: 0 4px;
  `)}
`;

export const Pagination = styled.div`
  position: absolute;
  width: 100%;
  bottom: -35px;
  text-align: center;
  margin-top: 10px;
  ${mediaMax('small',`
    margin-top: 10px;
    position: relative;
    bottom: auto;
  `)}
  z-index: 2;
`;

export const BackgroundColor = styled.div`
  ${absoluteFill}
  background-color: ${props => props.color};
`;

export const PaginationDot = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 2px;
  border: 1px solid ${colors.main};
  background-color: ${props => props.active ? colors.main : "transparent"};
  vertical-align: middle;
`;

export const ImgContainer = styled.div`
  position: absolute;
  height: ${props => props.fullscreen ? '100%' : 'calc(100% - 40px)'};
  width: ${props => props.fullscreen ? '100%' : '60%'};
  left: 0;
  top: 0;
  text-align: center;
  ${mediaMax('small',`
    position: relative;
    height: auto;
    width: auto;
    left: 0;
    top: 0;
    margin-top: 25px;
  `)}
`;

export const InfoContainer = styled.div`
  position: absolute;
  height: 80%;
  width: 32%;
  right: 4%;
  top: 10%;
  ${mediaMax('small',`
    position: relative;
    height: auto;
    width: auto;
    right: 0;
    top: 0;
    text-align: center;
    padding: 25px;
    h1 {
      margin-bottom: 10px;
    }
  `)}
`;

export const CenteredContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  > h1 {
    margin-bottom: 10px;
  }
  ${mediaMax('small',`
    position: relative;
    top: 0;
    transform: none;
  `)}
`;

export const LinkContainer = styled.div`
  width:100%;
  margin-bottom: 10px;
  a {
    color: ${colors.darkGray};
    font-weight: bold;
  }
`;

export const Icon = styled.img`
  height: 13px;
  vertical-align: middle;
`;

export const SliderContainer = styled.div`
  position: relative;
  display: block;
  /* margin-top: 30px; */
  width: 100%;
  ${mediaMin('medium',`
    ${absoluteFill}
    margin-top: 0;
    .react-swipe-container{
      height: 100%;
      > div {
        height: 100%;
      }
    }
  `)}
`;

export const Img = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
`;

export const SlideContainer = styled.div`
  height: 100%;
  padding: 0 25px;
  box-sizing: border-box;
  ${mediaMax('small',`
    height: 380px;
  `)}
`;

export const SliderVideo = styled.video`
  ${center}
  max-width: 100%;
  max-height: 100%;
  stroke: 1px solid black;
`;

export const Divider = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  left: 60%;
  opacity: 0.3;
  background-color: ${colors.main};
  ${mediaMax('small',`
    display: none;
  `)}
`;
