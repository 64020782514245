import React, { useState }  from 'react';
import { Container, Video } from './styles';
import Loader from '../../Loader';

const SliderVideo = React.forwardRef(({url}, ref) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <Container>
      <Video src={url} ref={ref} autoPlay={false} muted loop playsInline onLoadedData={()=>{setLoaded(true)}} preload='auto'/>
      <Loader show={!loaded} />
    </Container>
  );
});

export default SliderVideo;
