import create from '../utilities/zustand/create';
import IconInsta from '../assets/IconInsta.svg';
import IconMail from '../assets/IconMail.svg';
import { emailAdress } from './DataService';

export const menuLinks = [
  {title: 'Home', url: '/'},
  {title: 'Illustration', url: '/illustration'},
  {title: 'Drawing', url: '/drawing'},
];

export const menuIcons = [
  {img: IconInsta, link: 'https://www.instagram.com/valentinscheiner/', imgAltText: 'Instagram Icon'},
  {img: IconMail, link: `mailto: ${emailAdress}`, imgAltText: 'Mail Icon'},
];

const useNavigationStore = create(module, (set, get) => ({
  mobileMenuOpen: false,
  setMobileMenuOpen: mobileMenuOpen => {
    set({mobileMenuOpen});
  },
}));

export default useNavigationStore;
