import React, { useEffect } from 'react';
import './App.css';
import Menu from './components/Menu';
import useDataStore from './services/DataService';
import { useWindowStore } from './services/WindowService';
import { AppContainer } from './styles';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import BasicPage from './components/BasicPage';
import ProjectGrid from './components/ProjectGrid';
import Project from './components/Project';
import useNavigationStore from './services/NavigationService';
import MobileMenuOverlay from './components/MobileMenuOverlay';
import { useTrackingStore } from './services/TrackingService';
import Loader from './components/Loader';

function App() {
  const jwt = useDataStore(state => state.jwt);
  const projects = useDataStore(state => state.projects);
  const impressum = useDataStore(state => state.impressum);
  const datenschutz = useDataStore(state => state.datenschutz);
  const mobileMenuOpen =useNavigationStore(state => state.mobileMenuOpen);

  useEffect(()=>{
    document.title = "Valentin Scheiner";
    // useDataStore.getState().authorize();
    useDataStore.getState().getAllProjects();
    useWindowStore.getState().init();
    useTrackingStore.getState().init();
  }, [])

  // useEffect(()=>{
  //   if (jwt !== undefined) {
  //     useDataStore.getState().getAllProjects(jwt);
  //   }
  // }, [jwt]);


  // if(!projects) return <Loader show={true} delay={0}/>;

  return (
    <>
      {projects !== undefined && (
        <Router>
          <AppContainer>
            <Routes>
              <Route path="/" element={<ProjectGrid tag='home' />} />
              <Route path="/home/:id" element={<Project tag='home' />} />
              <Route path="/illustration" element={<ProjectGrid tag='illustration' />} />
              <Route path="/illustration/:id" element={<Project tag='illustration' />} />
              <Route path="/drawing" element={<ProjectGrid tag='drawing' />} />
              <Route path="/drawing/:id" element={<Project tag='drawing' />} />
              <Route path="/impressum" element={<BasicPage richtext={impressum} includeDatenschutzLink />} />
              <Route path="/datenschutz" element={<BasicPage richtext={datenschutz} />} />
              <Route path="*" element={<BasicPage richtext={"<h1>404</h1><p>This page doesn't exist.</p>"} />} />
            </Routes>
            <Menu />
          </AppContainer>
          {mobileMenuOpen && <MobileMenuOverlay />}
        </Router>
      )}
      <Loader show={projects === undefined} fixed />
    </>
  );
}

export default App;
