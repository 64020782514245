import React from 'react';
import { Container, Icon, IconContainer, MenuItem, MobileMenuContainer } from './styles';
import { Link, useLocation } from 'react-router-dom';
import useNavigationStore, { menuIcons, menuLinks } from '../../services/NavigationService';
import { getPathAndId } from '../../utilities/utilities';
import { useTrackingStore } from '../../services/TrackingService';

export default function MobileMenuOverlay() {
  const {pathname} = useLocation();
  const {path} = getPathAndId(pathname);

  const onMenuItemClick = ()=>{
    useNavigationStore.getState().setMobileMenuOpen(false);
  };

  return (
      <Container>
        <MobileMenuContainer>
          {menuLinks.map((ml, i) => {
            return <MenuItem underline={ml.url === '/'+path} index={i} key={i} onClick={onMenuItemClick}>
              <Link to={ml.url} onClick={()=>{useTrackingStore.getState().trackPageView(ml.url, ml.title)}}>
                {ml.title}
              </Link>
            </MenuItem>;}
          )}
        </MobileMenuContainer>
        <IconContainer>
          {menuIcons.toReversed().map((icon, key) =>
              <Link to={icon.link} key={key} target='_blank'>
                <Icon src={icon.img} />
              </Link>
          )}
          <div style={{marginTop: 5}}><Link to="/impressum" onClick={()=>{useTrackingStore.getState().trackPageView("/impressum", 'impressum'); onMenuItemClick()}}>legal notice</Link></div>
        </IconContainer>
      </Container>
  );
}