import create from '../utilities/zustand/create';
import { breakPoints } from '../common/breakpoints';
import debounce from '../utilities/debounce';
import { prefixEventListener } from '../utilities/prefixed-events';

export const useWindowStore = create(module, set => ({
  isMobile: false,
  breakpoint: undefined,
  windowWidth: 0,

  init: () => {
    const onResize = () => {
      const width = window.innerWidth;
      const breakpoint = (width <= breakPoints.xsmall.max) ? 'XS' : (width <= breakPoints.small.max) ? 'S' : (width <= breakPoints.medium.max) ? 'M' : 'L';
      set({ isMobile: width <= breakPoints.small.max, windowWidth: width, breakpoint });
    };

    prefixEventListener(window, 'resize', debounce(onResize, 250));
    onResize();
  },
}));
