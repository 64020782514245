import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../styles';
import { mediaMax } from '../../common/breakpoints';

export const Logo = ({color, width}) =>
  <span style={{width, display: "inline-block", float: "right"}}>
    <svg height="100%" width={width+"px"} viewBox="0 0 510 643" version="1.1" style={{fillRule:"evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:"82"}}>
      <g transform="matrix(1,0,0,1,-145.299,-940.641)">
          <g transform="matrix(1,0,0,1,0,853)">
              <g transform="matrix(-0.899261,-2.20255e-16,-2.20255e-16,0.899261,759.076,19.4879)">
                  <path d="M219.823,286.687C158.053,337.094 116.067,401.592 116.067,485.594C116.067,737.725 322.878,790.506 400,790.506C477.132,790.506 682.534,739.538 682.534,485.594C682.534,413.792 623.585,316.77 571.466,283.752L560.925,277.075L551.487,285.236C551.487,285.236 466.849,357.787 399.864,530.302C363.268,439.243 312.524,360.794 265.967,293.959C353.289,233.002 470.495,196.431 555.953,167.912C605.092,151.513 644.239,137.313 663.403,123.463C676.914,113.698 682.534,102.726 682.534,92.587L682.534,75.787L116.067,75.787L116.067,92.587C116.067,109.465 121.242,128.299 130.83,149.003C143.774,176.954 164.942,208.911 190.293,245.015C199.629,258.312 209.548,272.198 219.823,286.687ZM246.656,266.492C236.631,252.332 226.942,238.741 217.791,225.707C193.784,191.517 173.578,161.353 161.319,134.884C157.15,125.88 153.908,117.396 151.913,109.387L618.879,109.387C599.718,117.716 574.355,126.349 545.317,136.04C457.232,165.435 336.812,203.724 246.656,266.492ZM239.218,314.238C291.383,389.06 348.688,478.635 384.064,584.62L399.942,632.189L415.924,584.655C468.427,428.499 538.976,345.771 564.1,319.787C605.906,353.451 648.934,428.727 648.934,485.594C648.934,710.533 468.323,756.906 400,756.906C331.667,756.906 149.667,708.99 149.667,485.594C149.667,413.211 186.359,357.944 239.218,314.238Z" style={{fill:color}}/>
              </g>
          </g>
      </g>
    </svg>
  </span>
;

const slideInDesktop = keyframes`
  from { transform: translateX(-100%); opacity: 0 }
  to { transform: none; opacity: 1 }
`;

const slideInMobile = keyframes`
  from { transform: translateY(-100%); opacity: 0 }
  to { transform: none }
`;

export const MenuContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 160px;
  height:  100%;
  padding: 60px 30px 40px 0;
  background-color: ${colors.main};
  box-sizing: border-box;
  text-align: right;
  animation: ${slideInDesktop} 0.6s cubic-bezier(.17,.61,.49,.96) forwards;
  ${mediaMax('small',css`
    animation: ${slideInMobile} 0.6s cubic-bezier(.17,.61,.49,.96) forwards;
    width: 100%;
    height: 70px;
    padding: 0;
  `)}
  z-index: 1;
`;

export const BurgerBubble = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  ${props => props.inHeader && css`
    right: 5px;
    top: 4px;
    bottom: auto;
  `}
  width: 60px;
  height: 60px;
  border-radius: 1000px;
  background-color: ${colors.main};
  z-index: 3;
  transition: all 0.3s ease-out;
`;

export const BurgerMenu = styled.div`
  position: absolute;
  left: 21px;
  top: 23px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  > div {
    width: 100%;
    margin-bottom: 4px;
    height: 2px;
    background-color: white;
    transition: transform ease-out 0.25s, opacity linear 0.1s;
  }
  ${props => props.open && `
    > div.one {
      transform: translateY(6px) rotate(45deg);
    }
    > div.two {
      // transform: rotate(-45deg);
      opacity: 0;
    }
    > div.three {
      transform: translateY(-6px) rotate(-45deg);
    }
  `}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MenuToggle = styled.div`
  animation: ${fadeIn} 0.3s linear forwards;
  float: right;
  cursor: pointer;
`;

export const MenuItem = styled.div`
  margin-bottom: 3px;
  a {
    /* color: ${colors.main}; */
    color: white;
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
    font-size: 15px;
    /* font-weight: bold; */
  }
`;

export const LogoContainer = styled.div`
  float: right;
  width: 80px;
  margin-bottom: 50px;
  ${mediaMax('small',`
    margin: 0;
    position: absolute;
    width: auto;
    height: 40px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    img {
      height: 100%;
    }
  `)}
`;

export const IconContainer = styled.div`
  position: absolute;
  display: table-cell;
  vertical-align: bottom;
  right: 30px;
  bottom: 40px;
  >div > a {
    color: white;
    text-decoration: none;
    font-size: 10px;
    opacity: 0.7;
  }
`;

export const Icon = styled.img`
  float: right;
  height: 18px;
  margin-left: 8px;
`;