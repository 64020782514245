import create from '../utilities/zustand/create';

export const useTrackingStore = create(module, (set, get) => ({
  _paq: undefined,

  init: () => {
    let _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="//matomo.valentinscheiner.de/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '2']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
    set({_paq});
  },
  trackPageView: (url, title = undefined) => {
    const { _paq } = get();
    console.log(url, title.toLowerCase());
    _paq.push(['setCustomUrl', url]);
    if(title !== undefined) _paq.push(['setDocumentTitle', title]);
    _paq.push(['trackPageView']);
  },
}));
