import React from 'react';
import { Circle, Container } from './styles';

export default function Loader({show, delay = 400}) {

  return (
    <Container show={show} delay={delay}>
      <Circle deg={0} />
      <Circle deg={120} />
      <Circle deg={240} />
    </Container>
  );
}