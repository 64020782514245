import create from 'zustand';

function copyReactComponents(target, source) {
  function isObject(obj) {
    return typeof obj === 'object' && !!obj;
  }
  const reactMarker = 'symbol';
  function searchChildren(dst, src, depth) {
    if (depth > 10) return;
    for (const prop in dst) {
      if (isObject(dst[prop]) && isObject(src[prop])) {
        if (typeof dst[prop].$$typeof === reactMarker && typeof src[prop].$$typeof === reactMarker) {
          dst[prop] = src[prop];
        } else {
          searchChildren(dst[prop], src[prop], depth + 1);
        }
      }
    }
  }
  searchChildren(target, source, 0);
}

function newCreate(module, fn) {
  //no hot, just leave
  if (!module || !module.hot) return create(fn);

  //update
  if (global[module.id]) {
    const newStore = create(fn);
    const oldStore = global[module.id];
    const newState = newStore[1].getState();
    const oldState = oldStore[1].getState();
    copyReactComponents(oldState, newState);
    return oldStore;
  }

  //initial create
  global[module.id] = create(fn);
  return global[module.id];
}

export default newCreate;
